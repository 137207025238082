var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { "is-active": _vm.showRegionList },
      staticStyle: { "z-index": "1006 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("section", { staticClass: "modal-card-body notification" }, [
          _c(
            "button",
            {
              staticClass: "delete",
              attrs: { "aria-label": "close" },
              on: { click: _vm.toggleClose },
            },
            [_vm._v(" Close ")]
          ),
          _c("h3", { staticClass: "title is-6 has-text-success" }, [
            _vm._v("Regions"),
          ]),
          _c(
            "h6",
            { staticClass: "subtitle has-text-warning is-7 has-text-success" },
            [
              _vm._v(
                "Multiple distribution zones (hubs) can be linked to a region as a parent group."
              ),
            ]
          ),
          _c("table", { staticClass: "table is-fullwidth" }, [
            _vm._m(0),
            _vm.regionsList.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.regionsList, function (region) {
                    return _c("tr", { key: region.id }, [
                      _c("th", [_vm._v(_vm._s(region.name))]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-small is-success level-item",
                            on: {
                              click: function ($event) {
                                return _vm.openEditModal(region)
                              },
                            },
                          },
                          [_vm._v(" Edit ")]
                        ),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "button is-small is-warning level-item",
                            on: {
                              click: function ($event) {
                                return _vm.openDeleteModal(region)
                              },
                            },
                          },
                          [_vm._v(" Remove ")]
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c("div", [
              _vm.regionsList.length == 0
                ? _c("p", [_vm._v("No region found")])
                : _vm._e(),
            ]),
            _c("tfoot"),
          ]),
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column mx-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newRegion.name,
                    expression: "newRegion.name",
                  },
                ],
                attrs: { type: "text", placeholder: "Name" },
                domProps: { value: _vm.newRegion.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.newRegion, "name", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("footer", { staticClass: "card-footer" }, [
            _c(
              "button",
              {
                staticClass: "button is-success is-fullwidth is-small",
                class: _vm.isAdding ? "is-loading" : "",
                on: { click: _vm.addRegion },
              },
              [_vm._v(" Add ")]
            ),
          ]),
        ]),
      ]),
      _vm.showEditModal
        ? _c("RegionListEditModal", {
            attrs: {
              showEditModal: _vm.showEditModal,
              selectedRegion: _vm.selectedRegion,
              renderMap: _vm.renderMap,
            },
            on: { renderMap: _vm.toggleRender, closeModal: _vm.closeEditModal },
          })
        : _vm._e(),
      _vm.showDeleteModal
        ? _c("RegionListDeleteModal", {
            attrs: {
              showDeleteModal: _vm.showDeleteModal,
              selectedRegion: _vm.selectedRegion,
            },
            on: { closeModal: _vm.closeDeleteModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [_c("tr", [_c("th"), _c("th")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }