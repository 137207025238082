<template>
  <div
    class="modal"
    style="z-index: 1006 !important"
    v-bind:class="{ 'is-active': showRegionList }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body notification">
        <button class="delete" aria-label="close" @click="toggleClose">
          Close
        </button>
        <h3 class="title is-6 has-text-success">Regions</h3>
        <h6 class="subtitle has-text-warning is-7 has-text-success">Multiple distribution zones (hubs) can be linked to a region as a parent group.</h6>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th ></th>
              <th ></th>
            </tr>
          </thead>
          <tbody v-if="regionsList.length > 0">
            <tr v-for="region in regionsList" :key="region.id">
              <th>{{ region.name }}</th>
              <td >
                <button
                  class="button is-small is-success level-item"
                  @click="openEditModal(region)"
                >
                  Edit
                </button>
              </td>
              <td >
                <button
                  class="button is-small is-warning level-item"
                  @click="openDeleteModal(region)"

                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
          <div>
            <p v-if="regionsList.length == 0">No region found</p>
          </div>
          <tfoot></tfoot>
        </table>
        <div class="columns is-gapless">
          <div class="column mx-1">
            <input
              type="text"
              v-model="newRegion.name"
              placeholder="Name"
            />
          </div>
        </div>
        <footer class="card-footer">
          <button
            class="button is-success is-fullwidth is-small"

            v-bind:class="isAdding ? 'is-loading' : ''"
            @click="addRegion"
          >
            Add
          </button>
        </footer>
      </section>
    </div>
    <RegionListEditModal
      v-if="showEditModal"
      @renderMap="toggleRender"
      :showEditModal="showEditModal"
      :selectedRegion="selectedRegion"
      :renderMap="renderMap"
      @closeModal="closeEditModal"
    />
    <RegionListDeleteModal
      v-if="showDeleteModal"
      :showDeleteModal="showDeleteModal"
      :selectedRegion="selectedRegion"
      @closeModal="closeDeleteModal"
    />
  </div>
</template>
<script>
import { addRegion } from "@/store/fireStore";
import store from "../../store";

export default {
  components: {
    RegionListEditModal: () =>
      import("@/components/Region/RegionListEditModal"),
    RegionListDeleteModal: () =>
      import("@/components/Region/RegionListDeleteModal"),
  },
  data() {
    return {
      isAdding: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedRegion: null,
      newRegion: {
        name: "",
      },
    };
  },
  props: {
    showRegionList: {
      type: Boolean,
    },
  },
  methods: {
    toggleClose() {
      this.$emit("closeModal");
    },
    async toggleRender() {
      await this.$emit("renderMap");
    },
    async addRegion() {
      this.isAdding = true;
      if (this.newRegion.name === "") {
        this.isAdding = false;
        this.$notify({
          title: "Please enter a name",
          group: "zones",
          type: "error",
        });
        return;
      }
      try {
        await addRegion(this.newRegion.name);
        this.isAdding = false;
        this.$notify({
          type: "success",
          group: "zones",
          title: "Region Added Successfully",
        });
        this.newRegion.name = "";
      } catch (err) {
        this.isAdding = false;
        this.$notify({
          type: "error",
          group: "zones",
          title: "Error Adding Region",
        });
      }
    },
    openEditModal(zone) {
      this.showEditModal = true;
      this.selectedRegion = zone;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.selectedRegion = null;
    },
    openDeleteModal(zone) {
      this.showDeleteModal = true;
      this.selectedRegion = zone;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.selectedRegion = null;
    },
    renderMap() {
      this.$emit("renderMap");
    },
  },
  computed: {
    regionsList() {
      return this.$store.state.regions;
    },
    currentRegion() {
      return store.state.region;
    },
  },
};
</script>
